// STYLE
import "../scss/minicomponents/bio.scss";

// FONT AWESOME
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faEnvelope,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faLinkedinIn, faGithub } from "@fortawesome/free-brands-svg-icons";
const awsMapMarkerAlt = (
  <FontAwesomeIcon icon={faMapMarkerAlt} className="aws-bio" />
);
const awsEnvelope = <FontAwesomeIcon icon={faEnvelope} className="aws-bio" />;
const awsMobileAlt = <FontAwesomeIcon icon={faMobileAlt} className="aws-bio" />;
const awsLinkedinIn = (
  <FontAwesomeIcon icon={faLinkedinIn} className="aws-bio" />
);
const awsGithub = <FontAwesomeIcon icon={faGithub} className="aws-bio" />;
const Bio = () => {
  return (
    <div>
      <div className="bio-description">
        <h1>
          Artur <span>Lewandowicz</span>
        </h1>
        <div className="separator"></div>

        <p>
          I am a chef with a few years experience gained in the hotels and
          restaurants in Poland, Great Britain, Sweden & Norway. My aim is
          constant development and improving skills.
        </p>
        <div className="separator"></div>
        <ul>
          <li>
            <span>{awsLinkedinIn}</span> artur-lewandowicz
          </li>
          <li>
            <span>{awsGithub}</span> Arturowsky
          </li>
          <li>
            <span>{awsEnvelope}</span> artur.lewandowicz@gmail.com
          </li>
          <li>
            <span>{awsMobileAlt}</span> +48 515 164 915
          </li>
          <li>
            <span>{awsMapMarkerAlt}</span> Lodz, Poland
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Bio;
