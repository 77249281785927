// REACT AND HOOKS
import React, { useEffect, Component } from "react";

// GSAP
import { gsap } from "gsap";

// SRL
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
// STYLE
import "../scss/gallery.scss";

// IMAGES FULL WIDTH

import ja1 from "../images/gallery/ja1.jpg";
import ja2 from "../images/gallery/ja2.jpg";
import ja3 from "../images/gallery/ja3.jpeg";
import jalaponia from "../images/gallery/jalaponia.jpeg";
import food4 from "../images/gallery/food4.jpg";
import food3 from "../images/gallery/food3.jpg";
import food1 from "../images/gallery/food1.jpg";
import trout from "../images/gallery/trout.jpg";
import burrata from "../images/gallery/burrata.jpg";
import food2 from "../images/gallery/food2.jpg";
import scallops from "../images/gallery/scallops.jpg";
import superfood from "../images/gallery/superfood.jpg";
import foiegras from "../images/gallery/foiegras.jpg";
import dalenhand from "../images/gallery/dalenhand.jpg";
import veal from "../images/gallery/veal.jpg";
import dryage from "../images/gallery/dryage.jpg";
import halibut from "../images/gallery/halibut.jpg";
import ags from "../images/gallery/ags.jpg";
import alexanderbrasserie from "../images/gallery/alexanderbrasserie.jpg";
import alexanderdrawing from "../images/gallery/alexanderdrawing.jpg";
import alexandergarden from "../images/gallery/alexandergarden.jpg";
import alexanderpool from "../images/gallery/alexanderpool.jpg";
import cheese from "../images/gallery/cheese.jpg";
import crumble from "../images/gallery/crumble.jpg";
import duck from "../images/gallery/duck.jpg";
import duck2 from "../images/gallery/duck2.jpg";
import dalenfireplace from "../images/gallery/dalenfireplace.jpg";
import dalengarden from "../images/gallery/dalengarden.jpg";
import dalenhotel from "../images/gallery/dalenhotel.jpg";
import dalenrestaurant from "../images/gallery/dalenrestaurant.jpg";
import fish from "../images/gallery/fish.jpg";
import molten from "../images/gallery/molten.jpg";
import truffle from "../images/gallery/truffle.jpg";
import r3aa from "../images/gallery/r3aa.jpg";
import lamb from "../images/gallery/lamb.jpg";
import brioche from "../images/gallery/brioche.jpg";
import duckoys from "../images/gallery/duckoys.jpg";
import foie from "../images/gallery/foie.jpg";
import homepasta from "../images/gallery/homepasta.jpg";
import langusty from "../images/gallery/langusty.jpg";
import dalenfront from "../images/gallery/dalenfront.jpg";
import bestehotel from "../images/gallery/bestehotel.jpg";
import gazpacho from "../images/gallery/gazpacho.jpg";
import igloo from "../images/gallery/igloo.jpg";
import chefartur from "../images/gallery/chefartur.jpg";
import alexanderjacuzzi from "../images/gallery/alexanderjaccuzi.jpg";
import alexander3big from "../images/gallery/alexander3big.jpg";
import ambasador from "../images/gallery/ambasador.jpg";
import ambasadorp1big from "../images/gallery/ambasadorp1big.jpg";
import ambasadorp2big from "../images/gallery/ambasadorp2big.jpg";
import ambasadorp3big from "../images/gallery/ambasadorp3big.jpg";
import focus2big from "../images/gallery/focus2big.jpg";
import focus3big from "../images/gallery/focus3big.jpg";
import laponia from "../images/gallery/laponia.jpg";
import tiffi from "../images/gallery/tiffi.jpg";
import tiffi1big from "../images/gallery/tiffi1big.jpg";
import tiffi3big from "../images/gallery/tiffi3big.jpg";
import rancho2big from "../images/gallery/rancho2big.jpg";
import troutags from "../images/gallery/troutags.jpg";
import guideags from "../images/gallery/guideags.jpg";

// IMAGES THUMBS
import ja1_thumb from "../images/gallery/thumbs/ja1_thumb.jpg";
import ja2_thumb from "../images/gallery/thumbs/ja2_thumb.jpg";
import ja3_thumb from "../images/gallery/thumbs/ja3_thumb.jpg";
import jalaponia_thumb from "../images/gallery/thumbs/jalaponia_thumb.jpg";
import food4_thumb from "../images/gallery/thumbs/food4_thumb.jpg";
import food3_thumb from "../images/gallery/thumbs/food3_thumb.jpg";
import food1_thumb from "../images/gallery/thumbs/food1_thumb.jpg";
import trout_thumb from "../images/gallery/thumbs/trout_thumb.jpg";
import burrata_thumb from "../images/gallery/thumbs/burrata_thumb.jpg";
import food2_thumb from "../images/gallery/thumbs/food2_thumb.jpg";
import scallops_thumb from "../images/gallery/thumbs/scallops_thumb.jpg";
import superfood_thumb from "../images/gallery/thumbs/superfood_thumb.jpg";
import foiegras_thumb from "../images/gallery/thumbs/foiegras_thumb.jpg";
import dalenhand_thumb from "../images/gallery/thumbs/dalenhand_thumb.jpg";
import veal_thumb from "../images/gallery/thumbs/veal_thumb.jpg";
import dryage_thumb from "../images/gallery/thumbs/dryage_thumb.jpg";
import halibut_thumb from "../images/gallery/thumbs/halibut_thumb.jpg";
import ags_thumb from "../images/gallery/thumbs/ags_thumb.jpg";
import alexanderbrasserie_thumb from "../images/gallery/thumbs/alexanderbrasserie_thumb.jpg";
import alexanderdrawing_thumb from "../images/gallery/thumbs/alexanderdrawing_thumb.jpg";
import alexandergarden_thumb from "../images/gallery/thumbs/alexandergarden_thumb.jpg";
import alexanderpool_thumb from "../images/gallery/thumbs/alexanderpool_thumb.jpg";
import cheese_thumb from "../images/gallery/thumbs/cheese_thumb.jpg";
import crumble_thumb from "../images/gallery/thumbs/crumble_thumb.jpg";
import duck_thumb from "../images/gallery/thumbs/duck_thumb.jpg";
import duck2_thumb from "../images/gallery/thumbs/duck2_thumb.jpg";
import dalenfireplace_thumb from "../images/gallery/thumbs/dalenfireplace_thumb.jpg";
import dalengarden_thumb from "../images/gallery/thumbs/dalengarden_thumb.jpg";
import dalenhotel_thumb from "../images/gallery/thumbs/dalenhotel_thumb.jpg";
import dalenrestaurant_thumb from "../images/gallery/thumbs/dalenrestaurant_thumb.jpg";
import fish_thumb from "../images/gallery/thumbs/fish_thumb.jpg";
import molten_thumb from "../images/gallery/thumbs/molten_thumb.jpg";
import truffle_thumb from "../images/gallery/thumbs/truffle_thumb.jpg";
import r3aa_thumb from "../images/gallery/thumbs/r3aa_thumb.jpg";
import lamb_thumb from "../images/gallery/thumbs/lamb_thumb.jpg";
import brioche_thumb from "../images/gallery/thumbs/brioche_thumb.jpg";
import duckoys_thumb from "../images/gallery/thumbs/duckoys_thumb.jpg";
import foie_thumb from "../images/gallery/thumbs/foie_thumb.jpg";
import homepasta_thumb from "../images/gallery/thumbs/homepasta_thumb.jpg";
import langusty_thumb from "../images/gallery/thumbs/langusty_thumb.jpg";
import dalenfront_thumb from "../images/gallery/thumbs/dalenfront_thumb.jpg";
import bestehotel_thumb from "../images/gallery/thumbs/bestehotel_thumb.jpg";
import gazpacho_thumb from "../images/gallery/thumbs/gazpacho_thumb.jpg";
import igloo_thumb from "../images/gallery/thumbs/igloo_thumb.jpg";
import chefartur_thumb from "../images/gallery/thumbs/chefartur_thumb.jpg";
import alexanderjacuzzi_thumb from "../images/gallery/thumbs/alexanderjacuzzi_thumb.jpg";
import alexander3big_thumb from "../images/gallery/thumbs/alexander3big_thumb.jpg";
import ambasador_thumb from "../images/gallery/thumbs/ambasador_thumb.jpg";
import ambasadorp1big_thumb from "../images/gallery/thumbs/ambasadorp1big_thumb.jpg";
import ambasadorp2big_thumb from "../images/gallery/thumbs/ambasadorp2big_thumb.jpg";
import ambasadorp3big_thumb from "../images/gallery/thumbs/ambasadorp3big_thumb.jpg";
import focus2big_thumb from "../images/gallery/thumbs/focus2big_thumb.jpg";
import focus3big_thumb from "../images/gallery/thumbs/focus3big_thumb.jpg";
import laponia_thumb from "../images/gallery/thumbs/laponia_thumb.jpg";
import tiffi_thumb from "../images/gallery/thumbs/tiffi_thumb.jpg";
import tiffi1big_thumb from "../images/gallery/thumbs/tiffi1big_thumb.jpg";
import tiffi3big_thumb from "../images/gallery/thumbs/tiffi3big_thumb.jpg";
import rancho2big_thumb from "../images/gallery/thumbs/rancho2big_thumb.jpg";
import troutags_thumb from "../images/gallery/thumbs/troutags_thumb.jpg";
import guideags_thumb from "../images/gallery/thumbs/guideags_thumb.jpg";
// FONT AWESOME
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImages,
  faPepperHot,
  faMapMarkerAlt,
  faMale,
} from "@fortawesome/free-solid-svg-icons";
const awsImages = <FontAwesomeIcon icon={faImages} />;
const awsPepperHot = <FontAwesomeIcon icon={faPepperHot} />;
const awsMapfaMapMarkerAlt = <FontAwesomeIcon icon={faMapMarkerAlt} />;
const awsMale = <FontAwesomeIcon icon={faMale} />;
// FOOD IMAGES
const foodImages = [
  { img: truffle, thumb: truffle_thumb, bool: false, type: "food" },
  { img: foiegras, thumb: foiegras_thumb, bool: false, type: "food" },
  { img: dalenhand, thumb: dalenhand_thumb, bool: false, type: "food" },
  { img: halibut, thumb: halibut_thumb, bool: false, type: "food" },
  { img: trout, thumb: trout_thumb, bool: false, type: "food" },
  { img: burrata, thumb: burrata_thumb, bool: false, type: "food" },
  { img: troutags, thumb: troutags_thumb, bool: false, type: "food" },
  { img: veal, thumb: veal_thumb, bool: false, type: "food" },
  { img: food2, thumb: food2_thumb, bool: false, type: "food" },
  { img: scallops, thumb: scallops_thumb, bool: false, type: "food" },
  { img: duckoys, thumb: duckoys_thumb, bool: false, type: "food" },
  { img: superfood, thumb: superfood_thumb, bool: false, type: "food" },
  { img: gazpacho, thumb: gazpacho_thumb, bool: false, type: "food" },
  { img: food1, thumb: food1_thumb, bool: false, type: "food" },
  { img: duck2, thumb: duck2_thumb, bool: false, type: "food" },
  { img: food4, thumb: food4_thumb, bool: false, type: "food" },
  { img: dryage, thumb: dryage_thumb, bool: false, type: "food" },
  { img: foie, thumb: foie_thumb, bool: false, type: "food" },
  { img: molten, thumb: molten_thumb, bool: false, type: "food" },
  { img: food3, thumb: food3_thumb, bool: false, type: "food" },
  { img: brioche, thumb: brioche_thumb, bool: false, type: "food" },
  { img: crumble, thumb: crumble_thumb, bool: false, type: "food" },
  { img: homepasta, thumb: homepasta_thumb, bool: false, type: "food" },
  { img: fish, thumb: fish_thumb, bool: false, type: "food" },
  { img: lamb, thumb: lamb_thumb, bool: false, type: "food" },
  { img: cheese, thumb: cheese_thumb, bool: false, type: "food" },
  { img: duck, thumb: duck_thumb, bool: false, type: "food" },
  { img: langusty, thumb: langusty_thumb, bool: false, type: "food" },
];

// WORKING PLACES IMAGES
const workImages = [
  { img: dalenfront, thumb: dalenfront_thumb, bool: false, type: "work" },
  { img: dalenhotel, thumb: dalenhotel_thumb, bool: false, type: "work" },
  { img: bestehotel, thumb: bestehotel_thumb, bool: false, type: "work" },
  { img: r3aa, thumb: r3aa_thumb, bool: false, type: "work" },

  { img: alexanderpool, thumb: alexanderpool_thumb, bool: false, type: "work" },
  { img: dalengarden, thumb: dalengarden_thumb, bool: false, type: "work" },
  {
    img: alexandergarden,
    thumb: alexandergarden_thumb,
    bool: false,
    type: "work",
  },
  {
    img: dalenfireplace,
    thumb: dalenfireplace_thumb,
    bool: false,
    type: "work",
  },
  {
    img: alexanderbrasserie,
    thumb: alexanderbrasserie_thumb,
    bool: false,
    type: "work",
  },
  { img: ags, thumb: ags_thumb, bool: false, type: "work" },
  {
    img: alexanderjacuzzi,
    thumb: alexanderjacuzzi_thumb,
    bool: false,
    type: "work",
  },
  {
    img: dalenrestaurant,
    thumb: dalenrestaurant_thumb,
    bool: false,
    type: "work",
  },
  {
    img: alexanderdrawing,
    thumb: alexanderdrawing_thumb,
    bool: false,
    type: "work",
  },
  {
    img: alexander3big,
    thumb: alexander3big_thumb,
    bool: false,
    type: "work",
  },
  {
    img: guideags,
    thumb: guideags_thumb,
    bool: false,
    type: "work",
  },
  {
    img: ambasador,
    thumb: ambasador_thumb,
    bool: false,
    type: "work",
  },
  {
    img: ambasadorp1big,
    thumb: ambasadorp1big_thumb,
    bool: false,
    type: "work",
  },
  {
    img: ambasadorp2big,
    thumb: ambasadorp2big_thumb,
    bool: false,
    type: "work",
  },
  {
    img: ambasadorp3big,
    thumb: ambasadorp3big_thumb,
    bool: false,
    type: "work",
  },
  {
    img: focus2big,
    thumb: focus2big_thumb,
    bool: false,
    type: "work",
  },
  {
    img: focus3big,
    thumb: focus3big_thumb,
    bool: false,
    type: "work",
  },
  {
    img: laponia,
    thumb: laponia_thumb,
    bool: false,
    type: "work",
  },
  {
    img: tiffi,
    thumb: tiffi_thumb,
    bool: false,
    type: "work",
  },
  {
    img: tiffi1big,
    thumb: tiffi1big_thumb,
    bool: false,
    type: "work",
  },
  {
    img: tiffi3big,
    thumb: tiffi3big_thumb,
    bool: false,
    type: "work",
  },
  {
    img: rancho2big,
    thumb: rancho2big_thumb,
    bool: false,
    type: "work",
  },
];
// DEV ARTUR IMAGES
const arturImages = [
  { img: ja3, thumb: ja3_thumb, bool: false, type: "me" },
  { img: jalaponia, thumb: jalaponia_thumb, bool: false, type: "me" },
  { img: ja1, thumb: ja1_thumb, bool: false, type: "me" },
  { img: ja2, thumb: ja2_thumb, bool: false, type: "me" },
  { img: chefartur, thumb: chefartur_thumb, bool: false, type: "me" },
  { img: igloo, thumb: igloo_thumb, bool: false, type: "me" },
];
// CONCAT ALL IMAGES INTO ONE ARRAY
const imageGalleryFullArray = workImages.concat(foodImages, arturImages);

const Template = (props) => {
  useEffect(() => {
    gsap.fromTo(
      ".gallery-box",
      {
        duration: 0.8,
        rotation: 20,
        scale: 0.1,
        x: 20,
        y: 20,
      },
      {
        rotation: 0,
        scale: 1,
        transformOrigin: "50px 20px",
        stagger: 0.05,
        opacity: 1,
        ease: "Ease.backout",
      }
    );
  });

  return (
    <div className="gallery-box">
      <a href={props.image} title={props.key}>
        <img src={props.thumb} className="gallery-img-thumb" alt={props.key} />
      </a>
    </div>
  );
};

class Gallery extends Component {
  state = {
    images: foodImages,
    active: false,
  };

  Active() {
    this.setState({
      active: !this.state.active,
    });
  }
  AllImages() {
    this.setState({
      images: imageGalleryFullArray,
    });
  }
  Food() {
    this.setState({
      images: foodImages,
    });
  }
  WorkPlaces() {
    this.setState({
      images: workImages,
    });
  }
  Artur() {
    this.setState({
      images: arturImages,
    });
  }
  render() {
    return (
      <div className="global-container">
        <div className="global-wrapper-1k">
          <div className="gallery-button-group">
            <button className="gallery-button" onClick={this.Food.bind(this)}>
              {awsPepperHot} Food images ({foodImages.length})
            </button>
            <button
              className="gallery-button"
              onClick={this.WorkPlaces.bind(this)}
            >
              {awsMapfaMapMarkerAlt} My working places ({workImages.length})
            </button>
            <button className="gallery-button" onClick={this.Artur.bind(this)}>
              {awsMale} Me, myself & I ({arturImages.length})
            </button>
            <button
              className="gallery-button"
              onClick={this.AllImages.bind(this)}
            >
              {awsImages} All pictures ({imageGalleryFullArray.length})
            </button>
          </div>
          <SimpleReactLightbox>
            <SRLWrapper>
              <div className="gallery-grid">
                {this.state.images.map((value, index) => (
                  <Template
                    index={value}
                    image={value.img}
                    thumb={value.thumb}
                    key={index}
                    boolean={value.bool}
                  />
                ))}
              </div>
            </SRLWrapper>
          </SimpleReactLightbox>
        </div>
      </div>
    );
  }
}

export default Gallery;
