import React from "react";

// CV COMPONENTS
import Bio from "../react-components/Bio";
import Download from "../react-components/Download";
import Hobby from "../react-components/Hobby";
import MyExperience from "../react-components/MyExperience";
import Jobs from "../react-components/Jobs";
import Edu from "../react-components/Education";
import Trainings from "../react-components/Trainings";

// MY PHOTO :)
import img from "../images/art2.jpg";

// STYLE
import "../scss/index.scss";
import "../scss/landing.scss";
import "../scss/global.scss";
import "../scss/homepage.scss";
import "../scss/minicomponents/modal.scss";
//
// FONT AWESOME
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUtensils,
  faGraduationCap,
  faMedal,
} from "@fortawesome/free-solid-svg-icons";
const awsUtensils = <FontAwesomeIcon icon={faUtensils} />;
const awsGraduationCap = <FontAwesomeIcon icon={faGraduationCap} />;
const awsMedal = <FontAwesomeIcon icon={faMedal} />;

const Homepage = () => {
  return (
    <div>
      <div className="global-wrapper-1k">
        <div className="grid-x-30-70">
          <div className="left-col">
            <div className="card-bio">
              <Bio />
            </div>
            <div className="card-blank">
              <img src={img} alt="" />
            </div>
            <div className="left-col-sticky">
              <div className="card">
                <Download />
              </div>

              <div className="card">
                <Hobby />
              </div>
            </div>
          </div>
          <div className="right-col">
            <div className="card-highlight">
              <MyExperience icon={awsUtensils} text="Professional Experience" />
            </div>
            <div className="card">
              <Jobs />
            </div>
            <div className="card-highlight">
              <MyExperience icon={awsGraduationCap} text="Education" />
            </div>
            <div className="card">
              <Edu />
            </div>
            <div className="card-highlight">
              <MyExperience icon={awsMedal} text="Trainings & Certificates" />
            </div>
            <div className="card">
              <Trainings />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
