import React, { useState } from "react";
// STYLES
import "../scss/global.scss";

// FONT AWESOME
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
// USE SCROLL POSITION HOOK
import { useScrollPosition } from "react-use-scroll-position";

const element = <FontAwesomeIcon icon={faChevronUp} />;
const ToTop = () => {
  const [scrollTop, setScrollTop] = useState("none");
  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < -1100) {
      setScrollTop("grid");
      console.log(currPos.y);
    }
    if (currPos.y > -300) {
      setScrollTop("none");
    }
  });
  return (
    <button
      className="rc-scrolltop"
      style={{ display: { scrollTop }, top: "80%" }}
      onClick={() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }}
    >
      {element}
    </button>
  );
};
export default ToTop;
