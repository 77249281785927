// MAIN APP COMPONENT
import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import ToTop from "./react-components/ToTop";

// Routes
import Homepage from "./routing-components/Homepage";
import Gallery from "./routing-components/Gallery";
import Contact from "./routing-components/Contact";
// STYLE
import "../src/scss/index.scss";
import "../src/scss/landing.scss";

export default function App() {
  return (
    <Router>
      <ToTop />
      <div>
        <header>
          <nav>
            <div className="site-name">
              <h2>
                artur<span>lewandowicz</span>
              </h2>
            </div>
            <div>
              <ul>
                <li>
                  <Link className="nav-links" to="/">
                    Home
                  </Link>
                </li>

                <li>
                  <Link className="nav-links" to="/pictures">
                    Pictures
                  </Link>
                </li>
                <li>
                  <Link className="nav-links" to="/contact">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </header>

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/pictures">
            <Gallery />
          </Route>

          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/">
            <Homepage />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
