import "../scss/contact.scss";
// FONT AWESOME
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookMessenger,
  faGithub,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
const awsFacebookMessenger = (
  <FontAwesomeIcon icon={faFacebookMessenger} className="aws-contact" />
);
const awsGithub = <FontAwesomeIcon icon={faGithub} className="aws-contact" />;
const awsLinkedinIn = (
  <FontAwesomeIcon icon={faLinkedinIn} className="aws-contact" />
);
const awsEnvelope = (
  <FontAwesomeIcon icon={faEnvelope} className="aws-contact" />
);

const Contact = () => {
  return (
    <div>
      <div className="global-wrapper-1k">
        <div className="contact-wrapper">
          <div className="contact-text">
            <div className="contact-text-details">
              <h1>Contact</h1>
              <h2>
                Interested in working together? We should queue up a chat. I’ll
                buy the coffee.
              </h2>
              <p></p>
              <h3>
                How can <span>YOU</span> reach me out ?
              </h3>
              <div className="contact-icons">
                <a href="https://www.facebook.com/artur.lewandowicz">
                  {awsFacebookMessenger}
                </a>{" "}
                <a href="https://www.linkedin.com/in/artur-lewandowicz-753457108/">
                  {awsLinkedinIn}
                </a>
                <a href="https://github.com/Arturowsky">{awsGithub}</a>
                <a href="mailto:artur.lewandowicz@gmail.com">{awsEnvelope}</a>
              </div>
            </div>
          </div>
          <div className="contact-form">
            <form>
              <label htmlFor="fname">Name:</label>
              <input
                type="text"
                id="fname"
                name="fname"
                placeholder="Your name"
              />
              <label htmlFor="mail">E-mail:</label>
              <input
                type="text"
                id="mail"
                name="mail"
                placeholder="Your e-mail"
              />
              <label htmlFor="area">Message:</label>
              <textarea rows="5" placeholder="Your message"></textarea>
              <input type="submit" value="Submit" />
              <p>All fields required</p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
