// STYLES FROM EDUCATION
import "../scss/minicomponents/edu.scss";
// IMAGES
import bos from "../images/bos.png";
import makro from "../images/makro.png";
import flow from "../images/flow.png";
import hasco from "../images/hasco.png";
// FONT AWESOME
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVial,
  faChartBar,
  faFish,
  faPizzaSlice,
  faHamburger,
  faAllergies,
  faPumpSoap,
} from "@fortawesome/free-solid-svg-icons";

const awsVial = <FontAwesomeIcon icon={faVial} />;
const awsChartBar = <FontAwesomeIcon icon={faChartBar} />;
const awsFish = <FontAwesomeIcon icon={faFish} />;
const awsPizzaSlice = <FontAwesomeIcon icon={faPizzaSlice} />;
const awsHamburger = <FontAwesomeIcon icon={faHamburger} />;
const awsAllergies = <FontAwesomeIcon icon={faAllergies} />;
const awsPumpSoap = <FontAwesomeIcon icon={faPumpSoap} />;

const Trainings = () => {
  return (
    <div>
      <div className="edu-wrapper">
        <div className="edu-image">
          <img src={bos} alt="" />
        </div>
        <div className="edu-border">
          <h2>Jean Bos Molecular Cuisine</h2>
          <p>
            A training was conducted by Jean Bos, a chef who developed his
            skills, among others, at the royal court in Belgium and Monaco. He
            worked in a restaurant having 2 stars in Michelin catalogue, and he
            established the first academy of molecular cuisine in Poland.
          </p>
          <button className="btn-trainings">{awsVial} Molecular cuisine</button>
        </div>
      </div>
      <div className="separator" style={{ margin: "20px 0" }}></div>
      <div className="edu-wrapper">
        <div className="edu-image">
          <img src={makro} alt="" />
        </div>
        <div className="edu-border">
          <h2>Makro Academy</h2>
          <p>
            According to the ranking of Financial Times, Leon Koźmiński
            University is the best business school in central and eastern
            Europe. In 2011, Leon Koźmiński University, for the twelfth time,
            was on the FIRST PLACE among non-public universities in the ranking
            of Perspektywy, Rzeczpospolita and Wprost.
          </p>
          <div className="btn-group">
            <button className="btn-trainings">{awsChartBar} HACCP</button>
            <button className="btn-trainings">{awsFish} Fishes</button>
            <button className="btn-trainings">{awsPizzaSlice} Pizza</button>
            <button className="btn-trainings">{awsHamburger} BBQ</button>
          </div>
        </div>
      </div>
      <div className="separator" style={{ margin: "20px 0" }}></div>
      <div className="edu-wrapper">
        <div className="edu-image">
          <img src={flow} alt="" />
        </div>
        <div className="edu-border">
          <h2>Flow Hospitality Training</h2>
          <p>
            On-line trainings conducted at the request of my British employer.
            In accordance with regulations, since 2014, staff should, at the
            request of guests, make information about composition of products in
            dishes available and undergo full training within this scope.
          </p>
          <button className="btn-trainings">
            {awsAllergies} Food Alergens
          </button>
        </div>
      </div>
      <div className="separator" style={{ margin: "20px 0" }}></div>
      <div className="edu-wrapper">
        <div className="edu-image">
          <img src={hasco} alt="" />
        </div>
        <div className="edu-border">
          <h2>iHasco</h2>
          <p>
            Food safety & hygiene lvl.2 - training completed during work at
            Alexander House Hotel. Gained 95% score.
          </p>
          <button className="btn-trainings">
            {awsPumpSoap} Food Safety & Hygiene lvl.2
          </button>
        </div>
      </div>
      <div className="separator" style={{ margin: "20px 0" }}></div>
    </div>
  );
};

export default Trainings;
