// STYLE
import "../scss/minicomponents/edu.scss";
// IMAGES
import school from "../images/gastroschool.png";
// FONT AWESOME
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

const awsMapMarkerAlt = (
  <FontAwesomeIcon icon={faMapMarkerAlt} className="aws-bio" />
);

const Edu = () => {
  return (
    <div>
      <div className="edu-wrapper">
        <div className="edu-image">
          <img src={school} alt="" />
        </div>
        <div className="edu-border">
          <h2>Gastronomy School</h2>
          <p>
            Foodservice Technical School as a school complementing professional
            experience.
          </p>
          <p>{awsMapMarkerAlt} Sienkiewicza 88, 90-563 Lodz, Poland</p>
          <p className="para-cutout">Secondary School Certificate 2007</p>
        </div>
      </div>
      <div className="separator" style={{ margin: "20px 0" }}></div>
      <div className="edu-wrapper">
        <div className="edu-image">
          <img src={school} alt="" />
        </div>
        <div className="edu-border">
          <h2>Complex of Gastronomy School's</h2>
          <p>A three-year professional school with a chef profile.</p>
          <p>{awsMapMarkerAlt} Sienkiewicza 88, 90-563 Lodz, Poland</p>
          <p className="para-cutout">Graduated to cook 2003</p>
        </div>
      </div>
    </div>
  );
};

export default Edu;
