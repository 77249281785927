const MyExperience = (props) => {
  return (
    <div>
      <h1 style={{ color: "whitesmoke" }}>
        {props.icon} {props.text}
      </h1>
    </div>
  );
};
export default MyExperience;
