// REACT AND HOOKS
import React, { useState, useRef } from "react";

// GSAP
import { gsap } from "gsap";

import { CustomEase } from "gsap/CustomEase";
import { CustomBounce } from "gsap/CustomBounce";

// LOGO IMAGES
import dalenlogo from "../images/dalen2.png";
import rancho from "../images/rancho.png";
import tiffi from "../images/tiffi2.png";
import gardeners from "../images/gardeners.png";
import laponia from "../images/laponia.png";
import ambasador from "../images/ambasador2.png";
import ambasadorB from "../images/ambasador2a.png";
import alexander from "../images/alexanderhouse2.png";
import focus from "../images/focus2.png";

// JOB DESCRIPTION IMAGES
import dalen from "../images/dalen1big.jpg";
import dalen2 from "../images/dalen3big.jpg";

// STYLE
import "../scss/index.scss";
import "../scss/global.scss";
import "../scss/landing.scss";
import "../scss/minicomponents/jobs.scss";

// FONT AWESOME
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faExternalLinkAlt,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons";

const awsMapMarkerAlt = <FontAwesomeIcon icon={faMapMarkerAlt} />;
const awsExternalLinkAlt = <FontAwesomeIcon icon={faExternalLinkAlt} />;
const awsMobileAlt = <FontAwesomeIcon icon={faMobileAlt} />;

gsap.registerPlugin(CustomEase, CustomBounce);

const Places = (props) => {
  const [openDetails, setOpenDetails] = useState(false);
  const [summaryText, setSummaryText] = useState(" [open]");
  const jobsInfoRef = useRef();
  const jobsPicturesRef = useRef();
  const descriptionRef = useRef();

  const handleClick = () => {
    setOpenDetails(!openDetails);
    if (openDetails === false) {
      gsap.from(
        [jobsInfoRef.current, jobsPicturesRef.current, descriptionRef.current],
        {
          y: 50,
          opacity: 0,
        }
      );
      setSummaryText(" [close]");
    }

    if (openDetails === true) {
      gsap.to(
        [jobsInfoRef.current, jobsPicturesRef.current, descriptionRef.current],
        {
          y: 0,
          opacity: 1,
          duration: 1,
        }
      );
      setSummaryText(" [open] ");
    }
  };
  return (
    <div>
      <div className="jobs">
        <div>
          <img src={props.logo} alt="" />
        </div>
        <div className="jobs-border">
          <h2>{props.name}</h2>
          <p>{props.position}</p>
          <p>{props.hired}</p>
          <details open={props.open} onClick={handleClick}>
            <summary>
              More about {props.summary}
              {summaryText}
            </summary>

            <p ref={descriptionRef}>{props.descripition}</p>
            <div className="jobs-separator"></div>
            <div className="jobs-info" ref={jobsInfoRef}>
              <div style={{ display: "flex", marginTop: "10px" }}>
                <p>{awsMapMarkerAlt}</p>
                <p>{props.address}</p>
              </div>
              <div style={{ display: "flex", marginTop: "10px" }}>
                <p>{awsExternalLinkAlt}</p>
                <p>
                  <a
                    href={props.www}
                    title={`Go to ${props.name} website which can be found at ${props.www}`}
                  >
                    {props.www}
                  </a>
                </p>
              </div>
              <div style={{ display: "flex", marginTop: "10px" }}>
                <p>{awsMobileAlt}</p>
                <p>{props.tel}</p>
              </div>
            </div>
          </details>
        </div>
      </div>

      <div className="jobs-separator"></div>
    </div>
  );
};
const Jobs = () => {
  const jobDescription = [
    {
      name: "Dalen Hotel *****",
      summary: "Dalen Hotel",
      logo: dalenlogo,
      position: "Sous chef",
      hired: "April 2019 - at present",
      descripition:
        "Dalen Hotel is a historic hotel located at Dalen in the municipality of Tokke in Telemark, Norway at Bandak lake. The luxury Dalen Hotel, once a popular locale for European royalty, is one of the largest wooden buildings in Norway and one of the best preserved hotels of its size from the 1800s. The hotel owners operate a ferry service along the Telemark Canal, which during the warmer seasons has daily trips between Skien and Dalen on the historic passenger ships MS Henrik Ibsen and MS Victoria.",
      img1: dalen,
      img2: dalen2,
      img3: dalen,
      img4: dalen,
      www: "https://www.dalenhotel.no/",
      address: "Hotellvegen 33, 3880 Dalen, Norway",
      tel: "+47 35 07 90 00",
      booking: "booking.com/",
      open: false,
    },
    {
      name: "Hotell Laponia & Konferens ****",
      summary: "Hotell Laponia & Konferens",
      logo: laponia,
      position: "Chef de partie",
      hired: "January 2019 - March 2019",
      descripition:
        "Hotel Laponia & Konferens is located in the north of Sweden in the picturesque Arvidsjaur. If you dream about it to see auroras, you can not choose better. Spectacular views, Scandinavian delicacies, races on frozen lakes and many other attractions that are only available in the land of Santa Claus.",
      img1: dalen,
      img2: dalen2,
      img3: dalen,
      img4: dalen,
      www: "https://hotell-laponia.se/",
      address: "Storgatan 45, 933 33 Arvidsjaur, Sweden",
      tel: "+46 960 555 00",
      booking: "booking.com/",
      open: false,
    },
    {
      name: "Hotel Ambasador Premium ****",
      summary: "Ambasador Premium",
      logo: ambasador,
      position: "Sous chef",
      hired: "September 2018 - December 2018",
      descripition:
        "The four-star Hotel Ambasador Premium Lodz is conveniently situated in the heart of the city. In the immediate vicinity of the hotel there are shopping and leisure centres. Moreover, the hotel is only five-minute walk away from the world - famous Piotrkowska Street where you can not only breathe the unique atmosphere of the city but also enjoy its numerous restaurants, pubs and bars. Hotel is situated only 800 meters form Lodz Fabryczna railway station.",
      img1: dalen,
      img2: dalen2,
      img3: dalen,
      img4: dalen,
      www: "https://www.hotelambasador.eu/",
      address: "Kilinskiego 145, 90-315 Lodz, Poland",
      tel: "+48 (42) 677 15 40",
      booking: "booking.com/",
      open: false,
    },
    {
      name: "Restaurant Rancho",
      summary: "Restaurant Rancho",
      logo: rancho,
      position: "Head chef",
      hired: "September 2016 - August 2018",
      descripition:
        "Restaurant Rancho is part of one of the biggest horse club in Poland, located in Wiaczyn Dolny, next to Lodz. The stud has about 200 horses ready to provide attractions to all visitors, from horseback trips to the forest to horse riding lessons.",
      img1: dalen,
      img2: dalen2,
      img3: dalen,
      img4: dalen,
      www: "http://www.restauracjarancho.pl/",
      address: "Wiaczyn Dolny 58A, Lodz",
      tel: "+48 697 870 028",
      booking: "booking.com/",
      open: false,
    },
    {
      name: "Alexander House Hotel & Utopia SPA *****",
      summary: "Alexander House",
      logo: alexander,
      position: "Chef de partie",
      hired: "September 2016 - August 2018",
      descripition:
        "Alexander House is a beautiful arts and crafts style spa hotel set in the heart of the glorious Sussex countryside. Alexander House is a luxury manor house with beautiful vistas, a choice of restaurants, an award-winning Utopia Spa and rooms designed for absolute comfort. Five starred hotel is located on the borders of Sussex and Surrey, yet convenient to Gatwick Airport and only 55 minutes from London.",
      img1: dalen,
      img2: dalen2,
      img3: dalen,
      img4: dalen,
      www: "https://www.alexanderhotels.co.uk/alexander-house/",
      address: "East Grinstead, RH10 4QD, West Sussex, United Kingdom",
      tel: "+44 (0)1342 714914",
      booking: "booking.com/",
      open: false,
    },
    {
      name: "Gardeners Arms",
      summary: "Gardeners Arms",
      logo: gardeners,
      position: "Chef de partie",
      hired: "May 2014 - April 2015",
      descripition:
        "Pub & restaurant Gardeners Arms is located in southern England in Sussex county. It is situated between South of England Groundshow where one one of the biggest antique fairs in England takes place and botanical garden Wakehurst which covers an area of 180 acres. It is a wonderful place for a meal after exhausting walk. The kitchen serves traditional British dishes based on fresh products with the addition of herbs from their own garden.",
      img1: dalen,
      img2: dalen2,
      img3: dalen,
      img4: dalen,
      www: "https://www.gardenersarmsardingly.co.uk/",
      address: "Ardingly RH17 6TJ, West Sussex, United Kingdom",
      tel: "+44 01444 892 328",
      booking: "booking.com/",
      open: false,
    },
    {
      name: "Hotel Ambasador Centrum ****",
      summary: "Ambasador Centrum",
      logo: ambasadorB,
      position: "Chef de partie > Senior chef de partie",
      hired: "October 2010 - June 2013 & April 2015 - October 2015",
      descripition:
        "The four-star hotel Ambasador Centrum is located in the centre of Lodz, only 5 minutes of walk from historic Piotrkowska street. Guests have exclusive rooms at their disposal, as well as recreation centre, including swimming pool and saunas. Apart from service of individual guests, the hotel organizes business conferences and parties. Hotel restaurant for 180 people serves dishes of European cuisine.",
      img1: dalen,
      img2: dalen2,
      img3: dalen,
      img4: dalen,
      www: "https://www.hotelambasador.eu/",
      address: "Pilsudskiego 29, 90-307 Lodz, Poland",
      tel: "+48 (42) 677 15 40",
      booking: "booking.com/",
      open: false,
    },
    {
      name: "Tiffi Boutique Hotel *****",
      summary: "Tiffi Boutique Hotel",
      logo: tiffi,
      position: "Chef de partie",
      hired: "June 2013 - May 2014",
      descripition:
        "Located on a lake Ukiel, YachtClub Tiffi has a modern restaurant for 150 people where exclusive dishes of Mediterranean cuisine are served. Chef offers wide choice of delicious fish and meat dishes, made of high-quality natural products, without artificial additions and preservatives. When I was working in the YachtClub Tiffi, I was trained by a chef, Grzegorz Labuda - the author of culinary books and winner of the seventh edition of the competition Wine & Food Noble Night in a category main dish.",
      img1: dalen,
      img2: dalen2,
      img3: dalen,
      img4: dalen,
      www: "http://www.yachtclub.tiffi.com/pl/",
      address: "Zeglarska 7, 10-160 Olsztyn, Poland",
      tel: "+48 (89) 555 20 01",
      booking: "booking.com/",
      open: false,
    },
    {
      name: "Hotel Focus ***",
      summary: "Hotel Focus",
      logo: focus,
      position: "Demi chef de partie > Chef de partie",
      hired: "June 2013 - May 2014",
      descripition:
        "Hotel Focus is situated in a historic building from 1890, which housed Juliusz Kindermanns cotton factory in the past. Currently, the hotel consists of modern and spacious rooms, provides access to sauna and mini-gym. Conferences and special events are organized in this hotel. There is also a huge restaurant, in which you can eat dishes of Polish and regional cuisine.",
      img1: dalen,
      img2: dalen2,
      img3: dalen,
      img4: dalen,
      www: "https://www.focushotels.pl/",
      address: "Lakowa 23/25, 90-563 Lodz, Poland",
      tel: "+48 (42) 637 12 00",
      booking: "booking.com/",
      open: false,
    },
  ];

  return (
    <div>
      {jobDescription.map((index) => {
        return (
          <Places
            name={index.name}
            summary={index.summary}
            logo={index.logo}
            position={index.position}
            hired={index.hired}
            descripition={index.descripition}
            img1={index.img1}
            img2={index.img2}
            img3={index.img3}
            img4={index.img4}
            www={index.www}
            address={index.address}
            tel={index.tel}
            open={index.open}
            key={index.name}
          />
        );
      })}
    </div>
  );
};
export default Jobs;
