// REACT AND HOOKS
import React, { useEffect, Component } from "react";
// GSAP
import { gsap } from "gsap";
// FONT AWESOME
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTerminal,
  faPlane,
  faGamepad,
  faMusic,
  faVideo,
  faCookieBite,
  faCameraRetro,
  faMobileAlt,
  faThumbsUp,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";
import {
  faHtml5,
  faCss3Alt,
  faSass,
  faJs,
  faReact,
  faNpm,
  faGit,
} from "@fortawesome/free-brands-svg-icons";
// STYLE
import "../scss/minicomponents/hobby.scss";

// FONT AWESOME FOR BUTTONS
const awsMobileAlt = (
  <FontAwesomeIcon icon={faMobileAlt} className="aws-hobby" />
);
const awsHeart = <FontAwesomeIcon icon={faHeart} />;
const awsTerminal = <FontAwesomeIcon icon={faTerminal} className="aws-hobby" />;
const awsPlane = <FontAwesomeIcon icon={faPlane} className="aws-hobby" />;
const awsGamepad = <FontAwesomeIcon icon={faGamepad} className="aws-hobby" />;
const awsMusic = <FontAwesomeIcon icon={faMusic} className="aws-hobby" />;
const awsVideo = <FontAwesomeIcon icon={faVideo} className="aws-hobby" />;
const awsCookieBite = (
  <FontAwesomeIcon icon={faCookieBite} className="aws-hobby" />
);
const awsCameraRetro = (
  <FontAwesomeIcon icon={faCameraRetro} className="aws-hobby" />
);

const awsThumbsUp = <FontAwesomeIcon icon={faThumbsUp} />;
const awsHtml5 = <FontAwesomeIcon icon={faHtml5} className="aws-hobby" />;
const awsCss3Alt = <FontAwesomeIcon icon={faCss3Alt} className="aws-hobby" />;
const awsSass = <FontAwesomeIcon icon={faSass} className="aws-hobby" />;
const awsJs = <FontAwesomeIcon icon={faJs} className="aws-hobby" />;
const awsReact = <FontAwesomeIcon icon={faReact} className="aws-hobby" />;
const awsNpm = <FontAwesomeIcon icon={faNpm} className="aws-hobby" />;
const awsGit = <FontAwesomeIcon icon={faGit} className="aws-hobby" />;

const interest = [
  { icon: awsTerminal, name: "Programming" },
  { icon: awsPlane, name: "Travelling" },
  { icon: awsGamepad, name: "Gaming" },
  { icon: awsMusic, name: "Listening" },
  { icon: awsVideo, name: "Movies" },
  { icon: awsCookieBite, name: "Good food" },
  { icon: awsCameraRetro, name: "Pictures" },
  { icon: awsMobileAlt, name: "Technology" },
];
const ilike = [
  { icon: awsHtml5, name: "HTML 5" },
  { icon: awsCss3Alt, name: "CSS 3" },
  { icon: awsSass, name: "Sass" },
  { icon: awsJs, name: "Javascript" },
  { icon: awsReact, name: "React" },
  { icon: awsNpm, name: "npm" },
  { icon: awsGit, name: "Git" },
  { icon: awsGit, name: "Git" },
];

const HobbyCards = (props) => {
  useEffect(() => {
    gsap.fromTo(
      ".hobby-effect",
      {
        duration: 0.8,
        x: 30,
        opacity: 0,
      },
      {
        x: 0,
        y: 0,
        stagger: 0.05,
        opacity: 1,
      }
    );
  });
  useEffect(() => {
    gsap.set(".left-col-sticky", {
      position: "sticky",
      top: "80",
    });
  });
  return (
    <li className="hobby-effect">
      <span>{props.icon}</span> {props.name}
    </li>
  );
};
class Hobby extends Component {
  state = {
    hobby: interest,
    toggle: false,
    btnStateInterest: true,
    btnStateLiked: false,
  };

  handleInterests = () => {
    this.setState({
      hobby: interest,
      btnStateInterest: true,
      btnStateLiked: false,
    });
  };
  handleLiked = () => {
    this.setState({
      hobby: ilike,
      btnStateLiked: true,
      btnStateInterest: false,
    });
  };
  render() {
    return (
      <div>
        <div className="hobby-buttons-group">
          <button
            className={`hobby-button ${
              this.state.btnStateInterest === true ? "hobby-active" : null
            }`}
            onClick={this.handleInterests}
          >
            {awsThumbsUp} I Like{" "}
          </button>
          <button
            className={`hobby-button ${
              this.state.btnStateLiked === true ? "hobby-active" : null
            }`}
            onClick={this.handleLiked}
          >
            {awsHeart} Afterhours
          </button>
        </div>
        <div className="separator" style={{ margin: "15px 0" }}></div>
        <div className="hobby-container">
          <ul>
            {this.state.hobby.map((value, index) => (
              <HobbyCards
                icon={value.icon}
                name={value.name}
                key={value.name}
              />
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default Hobby;
